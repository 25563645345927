import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { AuthGuard, RoleGuard } from './shared/auth/auth.guard';

export const routes: Routes = [
{
	path: '',
	component: FullComponent,
	canActivate: [AuthGuard],
	children: [
	{ path: 'profile', loadChildren: './profile/profile.module#ProfileModule', canActivate: [AuthGuard, RoleGuard], data: {role: [1, 2, 3, 4, 7]} },
	{ path: 'change-password', loadChildren: './changepass/changepass.module#ChangePassModule', canActivate: [AuthGuard, RoleGuard], data: {role: [1]} },
	{ path: 'organization', loadChildren: './organization/organization.module#OrganizationModule', canActivate: [AuthGuard, RoleGuard], data: {role: [1, 2, 4, 7]}},
	{ path: 'user', loadChildren: './user/user.module#UserModule', canActivate: [AuthGuard, RoleGuard], data: {role: [1, 2, 4, 7]}},
	{ path: 'call-logs', loadChildren: './call-logs/call-logs.module#CallLogsModule', canActivate: [AuthGuard, RoleGuard], data: {role: [1, 2, 3, 4, 7]}},
	{ path: 'fv-note/:token', loadChildren: './fv-note/fv-note.module#FvNoteModule', canActivate: [AuthGuard, RoleGuard], data: {role: [5]}},
	{ path: 'ringcentral', loadChildren: './ringcentral-integration/ringcentral-integration.module#RingcentralIntegrationModule', canActivate: [AuthGuard, RoleGuard], data: {role: [2, 4, 7]}}
	]
},
{
	path: '',
	component: BlankComponent,
	children: [
	{
		path: '',
		loadChildren: './authentication/authentication.module#AuthenticationModule'
	}
	]
}, 
{
	path: '**',
	redirectTo: '404' 
}
];

@NgModule({
	imports: [RouterModule.forRoot(routes), NgbModule.forRoot()],
	exports: [RouterModule]
})

export class AppRoutingModule { }