export class AppSettings {
	constructor() {
	}
	
	//API Enpoint
	public static API_ENDPOINT = '/api/v1/';

	//For http://fvoice.reviewtestlink.com
	// public static API_ENDPOINT = 'https://fvoiceapi.reviewtestlink.com/api/v1/';

	//For https://fvoice.mvpcopy.net
	// public static API_ENDPOINT = 'https://fvoiceapi.mvpcopy.net/api/v1/';

	//Live eendpoint
	// public static API_ENDPOINT = 'https://api.voip-automation.com/api/v1/';

	//All APIS
	public static LOGIN = AppSettings.API_ENDPOINT + 'login/';
	public static FORGET_PASSWORD = AppSettings.API_ENDPOINT + 'forget-password/';
	public static CHANGE_PASSWORD = AppSettings.API_ENDPOINT + 'change-password/';
	public static RESET_PASSWORD = AppSettings.API_ENDPOINT + 'reset-password/';
	public static LOGOUT = AppSettings.API_ENDPOINT + 'logout/';
	public static PROFILE = AppSettings.API_ENDPOINT + 'profile/';
	public static ORGANIZATION = AppSettings.API_ENDPOINT + 'organization/';
	public static COUNTRY = AppSettings.API_ENDPOINT + 'country/';
	public static STATE = AppSettings.API_ENDPOINT + 'state/';
	// public static BASIC_SETTING = AppSettings.API_ENDPOINT + 'basic-setting/';
	public static UPLOAD = AppSettings.API_ENDPOINT + 'upload/';
	public static VERIFY_FV_API_KEY = AppSettings.API_ENDPOINT + 'verify-fv-api-key/';
	// public static AGENT = AppSettings.API_ENDPOINT + 'agent/';
	public static CALL_LOG = AppSettings.API_ENDPOINT + 'call-log/';
	// request call logs
	public static REQUEST_CALL_LOG = AppSettings.API_ENDPOINT + 'filevine-request-log/';
	// request call recordings 
	public static CALL_RECORDING = AppSettings.API_ENDPOINT + 'call-recording/';
	public static TEMPLATE = AppSettings.API_ENDPOINT + 'template/';
	public static NETSAPIEN_SUBSCRIPTION = AppSettings.API_ENDPOINT + 'netsapien-subscription/';
	// public static SYNC_NETSAPIEN_AGENT = AppSettings.API_ENDPOINT + 'sync-netsapien-agent/';
	public static ORG_TEMPLATE = AppSettings.API_ENDPOINT + 'org-template/';
	public static FV_USER_PROJECT = AppSettings.API_ENDPOINT + 'fv-user-project/';
	public static FILEVINE_GET_PROJECT = AppSettings.API_ENDPOINT + 'filevine-get-project/';
	public static PROJECT_DETAILS = AppSettings.API_ENDPOINT + 'project-details/';
	public static VERIFY_NETSAPIEN_DOMAIN = AppSettings.API_ENDPOINT + 'verify-netsapien-domain/';
	public static PASSWORD_FIND = AppSettings.API_ENDPOINT + 'password/find/';
	public static TEMPLATE_STATUS = AppSettings.API_ENDPOINT + 'template-status/';
	public static NETSAPIEN_USER = AppSettings.API_ENDPOINT + 'netsapien-user/';
	public static SYNC_NETSAPIEN_USER = AppSettings.API_ENDPOINT + 'sync-netsapien-user/';
	public static SHORT_CODE = AppSettings.API_ENDPOINT + 'short-code/';
	public static VERIFY_SECTION = AppSettings.API_ENDPOINT + 'verify-section/';
	public static FILEVINE_SECTION = AppSettings.API_ENDPOINT + 'filevine-section/';
	public static FORM_USER = AppSettings.API_ENDPOINT + 'form-user/';
	public static FILEVINE_PERSON = AppSettings.API_ENDPOINT + 'filevine-person/';
	public static FILEVINE_PERSON_PROJECT = AppSettings.API_ENDPOINT + 'filevine-person-project/';
	public static NOTES_CREATE = AppSettings.API_ENDPOINT + 'notes/create/';
	public static RINGCENTRAL_OAUTH = AppSettings.API_ENDPOINT + 'ringcentral-oauth/';
	public static RINGCENTRAL_EVENT = AppSettings.API_ENDPOINT + 'ringcentral-event/';
	public static GET_OAUTH_LINK = AppSettings.API_ENDPOINT + 'get-oauth-link/';
	public static GET_RINGCENTRAL_TOKEN = AppSettings.API_ENDPOINT + 'get-ringcentral-token/';
	public static GET_INTEGRATION_TYPES_USERS = AppSettings.API_ENDPOINT + 'form-user/';
	public static GET_INTEGRATION_TYPES_CALL_LOGS = AppSettings.API_ENDPOINT + 'call-logs/';
	public static GET_RINGCENTRAL_OAUTH_LINK = AppSettings.API_ENDPOINT + 'get-ringcentral-user-oauth-link/';
	public static AUTH_RINGCENTRAL_USER = AppSettings.API_ENDPOINT + 'generate-ringcentral-token/';
	public static VERIFY_FILEVINE_V2 = AppSettings.API_ENDPOINT + 'verify-filevine-v2/';
	public static FILEVINE_V2_GET_PROJECT = AppSettings.API_ENDPOINT + 'filevine-v2-get-project/';
	public static FILEVINE_V2_PROJECT_DETAILS = AppSettings.API_ENDPOINT + 'filevine-v2-project-details/';
	public static FV_V2_USER_PROJECT = AppSettings.API_ENDPOINT + 'fv-v2-user-project/';
	public static TIMEZONES=AppSettings.API_ENDPOINT+'timezones/';

	//subscription
	public static RINGCENTRAL_GET_SUBSCRIPTIONS = AppSettings.API_ENDPOINT + 'get_subscriptions/';
	public static RINGCENTRAL_GET_ALL_SUBSCRIPTIONS = AppSettings.API_ENDPOINT + 'get_all_subscriptions/';
	public static RC_DELETE_SUBSCRIPTION = AppSettings.API_ENDPOINT + 'delete_subscription/';

	
	/*added by abid*/
	public static RINGCENTRAL_STORE_PASSWORD = AppSettings.API_ENDPOINT + 'ringcentral-user-token/';
	public static RINGCENTRAL_DELETE_STORE_PASSWORD = AppSettings.API_ENDPOINT + 'ringcentral-user-token/';

	public static STORE_JWTTOKEN = AppSettings.API_ENDPOINT + 'ringcentral-jwt-token/';

	//stripe integration 
	public static CREDITCARD = AppSettings.API_ENDPOINT + 'card/';
	public static TRANSACTION_HISTORY = AppSettings.API_ENDPOINT + 'transaction-history/';
	public static EXPORT_TRANSACTION_HISTORY_CSV = AppSettings.API_ENDPOINT + 'export-transaction-history/';
	public static FILEVINE_FLAG = AppSettings.API_ENDPOINT + 'filevine-status/';
	//Patterns
	public static EMAIL_PATTERN = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$';
	public static CostPattern = '/^[0-9]+(\.[0-9]{1,2})?$/';
	public static TIME_OUT = 100;
	public static STRIPE_API_KEY = 'pk_live_pYyKV7otS5fxNMsh3TzOeiv1';


	//audit table
	public static AUDIT = AppSettings.API_ENDPOINT + 'audit/';

	//constants
	public static DELETE_SUBSCRIPTION_MESSAGE = "Deleted Subscription"
	public static getOrganisation() {
		return parseInt(localStorage.getItem('organization'));
	}
	
	public static getOrgIdFilterQuery(str) {
		let queryparam = '';
		if (this.getOrganisation()) {
			queryparam = str + 'org_id=' + this.getOrganisation();
		}
		return queryparam;
	}
}